import { useBranch } from "../../provider/BranchProvider";
import { useFormContext } from "react-hook-form";
import { Box, Grid } from "@mui/material";
import FormTextField from "../../components/form/FormTextField";
import { ofMembershipType } from "../../types/membershipType";
import FormImageField from "../../components/form/FormImageField";
import { CREDIT_CARD_ASPECT_PORTRAIT } from "../../types/membership";
import React from "react";

export function MembershipTypeForm() {
  const branch = useBranch();
  const { control } = useFormContext();
  return (
    <Box
      component="form"
      sx={{ display: "flex", flexDirection: "column" }}
      autoComplete="off"
    >
      <FormTextField
        name={ofMembershipType("name")}
        control={control}
        label={"Name"}
        required
        additionalRules={{
          maxLength: {
            value: 32,
            message: "Name must be at most 32 characters long",
          },
        }}
      />
      <FormTextField
        name={ofMembershipType("symbol")}
        control={control}
        label={"Symbol"}
        required
        additionalRules={{
          maxLength: {
            value: 10,
            message: "Symbol must be at most 10 characters long",
          },
        }}
      />
      <FormTextField
        name={ofMembershipType("description")}
        control={control}
        label={"Description"}
      />
      <FormTextField
        name={ofMembershipType("externalUrl")}
        control={control}
        label={"External Link"}
      />
      {branch?.coin && (
        <FormTextField
          name={ofMembershipType("includedCoinAmount")}
          control={control}
          onFocus={(event) => event.target.select()}
          type="number"
          fullWidth
          label="Included Coin amount"
          InputLabelProps={{ shrink: true }}
          helperText={
            "The amount of tokens a member will receive with this membership."
          }
          additionalRules={{
            min: 0,
          }}
          transform={{
            input: (v: any) => {
              if (!v) return v;
              return v * 100;
            },
            output: (v: any) => {
              if (!v) return v;
              return v / 100;
            },
          }}
        />
      )}
      <Grid container spacing={4} marginTop={1} direction={"column"}>
        <Grid item>
          <FormImageField
            control={control}
            name={ofMembershipType("image")}
            required
            aspect={CREDIT_CARD_ASPECT_PORTRAIT}
            format={" (ISO 7810)"}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
