import { DataGrid } from "@mui/x-data-grid";
import { useNavigation } from "@refinedev/core";
import { CreateButton, List } from "@refinedev/mui";
import { couponTypeCols } from "../../tables/couponTypeCols";
import { CouponType } from "../../types/couponType";
import { useBranchDependentDataGrid } from "../../utils/hooks/useBranchDependentDataGrid";
import { Typography } from "@mui/material";

export default function CouponList() {
  const { dataGridProps } = useBranchDependentDataGrid<CouponType>({});
  const { show } = useNavigation();

  return (
    <List headerButtons={<CreateButton>Create Coupon</CreateButton>}>
      <Typography variant={"subtitle1"} sx={{ mb: 3, mt: -3 }}>
        Coupon-types can be devalued from members at events. <br />
        You can drop coupons manually or create airdrop-rules.
      </Typography>
      <DataGrid
        {...dataGridProps}
        columns={couponTypeCols()}
        autoHeight
        onRowClick={(params) => {
          show("coupons", params.row.id);
        }}
        sx={{
          ...dataGridProps.sx,
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
        }}
      />
    </List>
  );
}
