import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useParsed } from "@refinedev/core";
import { CreateButton } from "@refinedev/mui";
import { useModalForm } from "@refinedev/react-hook-form";
import FormImageField from "components/form/FormImageField";
import FormTextField from "components/form/FormTextField";
import { useState } from "react";
import { FieldValues } from "react-hook-form";
import { defaultEventTicketType, TicketType } from "../../../types/ticketType";
import { CurrencyAmountField } from "../../../components/form/CurrencyAmountField";
import { TICKET_ASPECT_RATIO } from "../../../types/ticket";

export default function CreateTicketTypeDialog(props: {
  setFormValue: (ticket: TicketType) => void;
}) {
  const { id: eventId } = useParsed();

  const {
    control,
    handleSubmit,
    modal,
    reset,
    refineCore: { onFinish },
  } = useModalForm<TicketType>({
    refineCoreProps: {
      action: "create",
      resource: "ticket-types",
    },
    defaultValues: defaultEventTicketType,
  });

  const [pending, setPending] = useState(false);

  const handleSave = async (values: FieldValues) => {
    setPending(true);
    try {
      const ticket = await onFinish({
        ...values,
        priceInCents: values.priceInCents && values.priceInCents * 100,
        eventId: eventId,
      });
      ticket?.data && props.setFormValue(ticket.data);
    } finally {
      setPending(false);
      handleClose();
    }
  };

  const handleClose = () => {
    reset();
    modal.close();
  };

  return (
    <>
      <CreateButton onClick={() => modal.show()}>Create Ticket</CreateButton>
      <Dialog open={modal.visible} onClose={handleClose} fullWidth>
        <form onSubmit={handleSubmit(handleSave)}>
          <DialogTitle>Create Ticket</DialogTitle>
          <DialogContent>
            <Grid container flexDirection={"column"} spacing={4}>
              <Grid item flexDirection={"column"} display="flex">
                <FormTextField
                  name={"name"}
                  control={control}
                  label={"Name"}
                  required
                  additionalRules={{
                    maxLength: {
                      value: 32,
                      message: "Name must be at most 32 characters long",
                    },
                  }}
                />
                <FormTextField
                  name={"description"}
                  control={control}
                  label={"Description"}
                  multiline
                  additionalRules={{
                    maxLength: {
                      value: 250,
                      message:
                        "Description must be at most 250 characters long",
                    },
                  }}
                />
                <FormTextField
                  control={control}
                  name={"supply"}
                  label="Available quantity"
                  type="number"
                  additionalRules={{
                    min: 0,
                  }}
                />
                <CurrencyAmountField
                  control={control}
                  name={"price"}
                  label="Price"
                  required
                />
              </Grid>
              <Grid item>
                <FormImageField
                  control={control}
                  name={"image"}
                  required
                  aspect={TICKET_ASPECT_RATIO}
                  format={" (2:1)"}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} disabled={pending}>
              Cancel
            </Button>
            <LoadingButton variant="contained" type="submit" loading={pending}>
              Create
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
