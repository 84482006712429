import { DataGrid } from "@mui/x-data-grid";
import { useCan, useNavigation } from "@refinedev/core";
import { List, useDataGrid } from "@refinedev/mui";
import { useBranch } from "../../provider/BranchProvider";
import { membershipTypeCols } from "../../tables/membershipTypeCols";
import { MembershipType } from "../../types/membershipType";
import React from "react";
import { Typography } from "@mui/material";

export default function MembershipTypeList() {
  const branch = useBranch();
  const { edit } = useNavigation();

  const { data: canEdit } = useCan({ resource: "memberships", action: "edit" });

  const { dataGridProps } = useDataGrid<MembershipType>({
    filters: {
      permanent: [{ field: "branchId", operator: "eq", value: branch?.id }],
    },
  });

  return (
    <List>
      <Typography variant={"subtitle1"} sx={{ mb: 3, mt: -3 }}></Typography>
      <DataGrid
        {...dataGridProps}
        columns={membershipTypeCols({ coin: branch?.coin })}
        autoHeight
        columnVisibilityModel={{
          menu: canEdit?.can || false,
        }}
        onRowClick={(params) => {
          canEdit?.can && edit("memberships", params.row.id);
        }}
        sx={{
          ...dataGridProps.sx,
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
        }}
      />
    </List>
  );
}
