import { GridColDef } from "@mui/x-data-grid";
import {
  Transaction,
  ofTransactions,
  TransactionTransfer,
} from "../types/transaction";
import { Box, Tooltip } from "@mui/material";
import React from "react";
import { ImageColumn } from "../components/table/ImageColumn";
import { WalletDto } from "../types/walletDto";

export const transactionCols = (): GridColDef<Transaction>[] => {
  return [
    {
      field: ofTransactions("createdAt"),
      headerName: "Created at",
      flex: 1,
      renderCell: function render({ row }) {
        return <span>{new Date(row.createdAt).toLocaleString()}</span>;
      },
    },
    {
      field: ofTransactions("type"),
      headerName: "Transaction-Type",
      flex: 1,
    },
    {
      field: ofTransactions("transfers"),
      headerName: "Transfer",
      flex: 1,
      renderCell: (params) => {
        return (
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={1}
            width={"100%"}
            height={"100%"}
            alignItems={"center"}
          >
            {Array.isArray(params.value) ? (
              params.value.map((transfer: TransactionTransfer, index) => (
                <React.Fragment key={index}>
                  <span style={{ alignSelf: "center" }}>
                    {transfer.amount === -1 || transfer.amount === 1
                      ? 1
                      : transfer.amount / 100}
                  </span>
                  <Tooltip title={transfer.name} arrow>
                    <span style={{ cursor: "pointer" }}>
                      <ImageColumn
                        image={transfer.image}
                        borderRadius={"10%"}
                      />
                    </span>
                  </Tooltip>
                </React.Fragment>
              ))
            ) : (
              <span>No transfers available</span>
            )}
          </Box>
        );
      },
    },
    {
      field: ofTransactions("source"),
      headerName: "Source Wallet",
      flex: 1,
      renderCell: (params) => {
        const wallet = params.value as WalletDto;
        const address = wallet?.address || "";
        const formattedAddress = address
          ? `${address.slice(0, 4)}...${address.slice(-4)}`
          : "N/A";

        return (
          <Tooltip title={address} arrow>
            <span style={{ cursor: "pointer", color: "#1976d2" }}>
              {formattedAddress}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: ofTransactions("target"),
      headerName: "Target Wallet",
      flex: 1,
      renderCell: (params) => {
        const wallet = params.value as WalletDto;
        const address = wallet?.address || "";
        const formattedAddress = address
          ? `${address.slice(0, 4)}...${address.slice(-4)}`
          : "N/A";

        return (
          <Tooltip title={address} arrow>
            <span style={{ cursor: "pointer", color: "#1976d2" }}>
              {formattedAddress}
            </span>
          </Tooltip>
        );
      },
    },
  ];
};
